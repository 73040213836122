import './style.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Input } from '@bcmi-labs/react-components';
import { navigate } from '@reach/router';
import { Form, Formik } from 'formik';
import NewsletterSubscription from '../../../assets/NewsletterSubscription.svg';

const Subscription = ({ downloadUrl, setNewsletterInfo }) => {
  const { t } = useTranslation();
  const handleSubmit = (values, { setSubmitting }) => {
    fetch(`${process.env.GATSBY_API_URL}/consent/v1/subscription`, {
      method: 'POST',
      body: JSON.stringify(values),
    })
      .then(response => response.json())
      .then(response => setNewsletterInfo({ id: response.id, email: values.email }));
    window.open(downloadUrl);
    setSubmitting(false);
    navigate('/en/donate/learn-more');
  };

  return (
    <div className="newsletter-subscription">
      <h2>Stay in the Loop: Join Our Newsletter!</h2>
      <p>
        As a beginner or advanced user, you can find inspiring projects and learn about cutting-edge Arduino products
        through our <b>weekly newsletter</b>!
      </p>
      <Formik
        initialValues={{ email: '', privacy_approval: false, marketing_approval: false, newsletter_approval: true }}
        onSubmit={handleSubmit}
        validate={values => {
          const validateEmail = email => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          };

          const errors = {};

          if (!values?.email) {
            errors.email = 'The e-mail address is required';
          }

          if (!errors.email && !validateEmail(values?.email)) {
            errors.email = 'This e-mail address is not valid';
          }

          if (!values?.privacy_approval) {
            errors.privacy_approval = 'This field is required';
          }

          return errors;
        }}
      >
        {({ handleBlur, handleChange, isSubmitting, values, errors, touched }) => (
          <Form>
            <div className="newsletter-subscription__content">
              <div className="newsletter-subscription__email">
                <Input
                  label="email"
                  name="email"
                  isRequired
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && errors.email}
                />
              </div>
              <div className="newsletter-subscription__checkbox-container">
                <Checkbox
                  className="newsletter-subscription__checkbox"
                  name="privacy_approval"
                  value={values.privacy_approval}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.privacy_approval && errors.privacy_approval}
                  isRequired
                  label="I confirm to have read the [Privacy Policy](https://www.arduino.cc/PrivacyPolicy/en/) and to accept the [Terms of Service](https://www.arduino.cc/en/Main/TermsOfService)"
                />
                <Checkbox
                  className="newsletter-subscription__checkbox"
                  name="marketing_approval"
                  value={values.marketing_approval}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  label="I would like to receive emails about special deals and commercial offers from Arduino."
                />
              </div>
              <div className="newsletter-subscription__actions">
                <Button variant="primary" type="submit" isDisabled={isSubmitting}>
                  SUBSCRIBE & DOWNLOAD
                </Button>
                <span className="newsletter-subscription__or">or</span>
                <Button
                  variant="tertiary"
                  onPress={() => {
                    window.open(downloadUrl);
                    navigate('/en/software/download-thank-you');
                  }}
                >
                  {t('donate:justDownload')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="newsletter-subscription__image-container">
        <img src={NewsletterSubscription} alt="Newsletter subscription" />
      </div>
    </div>
  );
};

export default Subscription;
